<template>
  <add-edit-page>
    <template #header="{ validation }">
      <sticky-header title="Add Test Partner">
        <div class="document-toolbar">
          <div class="document-actions">
            <div class="button-group">
              <button class="button" :disabled="validation.pristine || validation.invalid || selectedLocation.length == 0" @click="handleSubmit">Save</button>
              <a class="button secondary" @click="$router.back()">Cancel</a>
            </div>
          </div>
        </div>
      </sticky-header>
    </template>
    <template>
        <div>
          <div class="grid-container">
           <div> <form-input  v-model="form.firstName"  label="First Name"  @input="checkFirst" />
            <p class="error-message" v-if="firstError">{{errMessage}}</p></div>
            <div>
            <form-input  v-model="form.lastName"  label="Last Name" rules="required" @input="checkSecond" />
            <p class="error-message" v-if="secondError">{{errMessages}}</p>

          </div>
            <form-input  v-model="form.email"  label="Email ID" required rules="required|walmartEmail"/>
            <form-input  v-model="form.phone"  mask label="Phone Number" rules="phone" />
            <form-input  v-model="form.ssn" label="SSN" :disabled="true" />
            <form-input  v-model="form.dlExpiration"  label="Driver License Expiry Date" :disabled="true"/>
            <form-input  v-model="form.dlNumber"  label="Driver License Number" :disabled="true"/>
            <form-input  v-model="form.city"  label="City" :disabled="true"/>
            <form-input  v-model="form.state"  label="State" :disabled="true"/>
            <form-input  v-model="form.postalCode"  label="Zip" :disabled="true"/>
            <form-input  v-model="form.country"  label="Country" :disabled="true"/>
            <div>
                <label>Location Name</label>
                <item-picker
                    :search-results="dropDownValues"
                    :max-size="1"
                    :noTitle="true"
                    placeholder="Type Location Name"
                    auto-select
                    @itemsChanged="updateLocation"
                    />
            </div>
          </div>
        </div>
    </template>
  </add-edit-page>
</template>
<script>
import AddEditPage from "@/components/layout/AddEditPage";
import StickyHeader from "@/components/layout/StickyHeader";
import FormInput from "@/components/forms/fields/FormInput";
import { ItemPicker } from "@/components";
import { restApi } from "@/http/http.config";
import { DateTime } from "luxon";
import { encodeWithParam } from "@/util/Base64Encoding";
import { encrypt } from "@/filters";

export default {
    name: "AddTestPartner",
    components: {AddEditPage,StickyHeader,FormInput,ItemPicker},
    data() {
        return {
            dropDownValues : [],
            selectedLocation: [],
            form: {
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                address1: "5396 North Reese Avenue",
                address2: "",
                city: "San Bruno",
                state: "CA",
                postalCode: "94066",
                country: "USA",
                dateOfBirth: "30-03-1991",
                ssn: "555-55-5555",
                dlState: "CA",
                dlNumber: "T0123456",
                dlExpiration: "2033-12-31",
                approvalDate: DateTime.utc().toISO().split("T")[0],
                locationID: "",
                errMessage:"",
                errMessages:"",
                secondError:false,
                firstError:false,

            },
        }
    },
    mounted() {
        restApi.get("/employee/getAllLocations").then((data)=>{
            if(data.data.success) {
                this.dropDownValues = data.data.result.result
            }
        })
    },
    methods: {
        async handleSubmit() {
            this.form.ssn = await encrypt(this.form.ssn.replace(/\D/g, ''))
            restApi.post("/employee/addTestPartner",encodeWithParam(this.form)).then(()=>{
                this.$router.back();
            })
        },
        updateLocation(location) {
            if(location.itemAdded) {
                this.selectedLocation.push( location.itemAdded);
                this.form.locationID = location.itemAdded.id;
            } else {
                this.selectedLocation = [];
                this.form.locationID = "";
            }
        },
        checkFirst(val){
          const space = /\s/;
          const letter = /^.*[^\w\s].*$|.*\d.*$/;
      if(letter.test(val)){
        this.firstError=true
        this.errMessage="First name cannot contain numbers or special characters."
            }
      else if(space.test(val)){
        this.firstError=true
        this.errMessage="First name cannot have spaces."
      }
      else return this.firstError=false
    },
    checkSecond(val){
          const space = /\s/;
          const letter = /^.*[^\w\s].*$|.*\d.*$/;
      if(letter.test(val)){
        this.secondError=true
        this.errMessages="Last name cannot contain numbers or special characters."
            }
      else if(space.test(val)){
        this.secondError=true
        this.errMessages="Last name cannot have spaces."
      }
      else return this.secondError=false
    },
    }
}
</script>
<style scoped>
    .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
.error-message {
  color: var(--error-40);
  margin-top: 4px;
}
</style>